import Lenis from 'lenis'
import { mobile } from './helpers/helpers';

let lenis;

if (!mobile) {
    lenis = new Lenis({
        duration: 0.8,
        easing: (x) => (1 - Math.pow(1 - x, 4)),
        direction: "vertical",
        gestureDirection: "vertical",
        smooth: true,
        smoothTouch: true,
        touchMultiplier: 2,
        autoResize: true
    });
    function raf(time) {
        lenis.raf(time)
        requestAnimationFrame(raf)
    }
    requestAnimationFrame(raf)
}

if (!mobile) {
    // lenis scroll to anchor global function
    $('.scrollToAnchor').on('click', function (e) {
        e.preventDefault();
        lenis.scrollTo('#' + this.dataset.target, {
            offset: window.innerWidth < 1200 ? (parseInt(this.dataset.offset)) / 2.5 : parseInt(this.dataset.offset),
            duration: this.dataset.duration ? this.dataset.duration : 2,
            easing: (x) => (x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2)
        });
    })
}



export {
    lenis
}