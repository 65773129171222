import.meta.glob([
    '../images/**',
    '../pdf/**',
    '../fonts/**',
    '../three/**',
]);

import './bootstrap';
import { lenis } from './lenis-init';
import * as THREE from 'three';
import * as materials from './materials';
import { mobile, onPointerMove, pointer, viewport, autoresize } from './helpers/helpers';
import { scene, renderer, camera, oControls, standaloneHDR } from './config/scene';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/addons/loaders/DRACOLoader.js';
import { directLight, backLight } from './config/lights';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);


// let loadingManager = new THREE.LoadingManager(),
//     dLoader = new DRACOLoader(),
//     gLoader = new GLTFLoader(loadingManager)

// // Draco loader setup
// dLoader.setDecoderPath('assets/js/draco/');
// gLoader.setDRACOLoader(dLoader)

// let model;
// gLoader.load(
//     "images/viewmodel_compressed.glb",
//     function (gltf) {
//         let s = 2.5;
//         model = gltf.scene;
//         model.scale.set(s, s, s);
//         model.traverse(function (child) {
//             if (child instanceof THREE.Mesh) {
//                 if (child.name.indexOf('surface') !== -1) {
//                     child.material = materials.glassMat;
//                     child.material.envMap = standaloneHDR;
//                 }

//                 child.castShadow = false;
//                 child.receiveShadow = false;
//             }
//         });
//         scene.add(model)
//     },
//     function (xhr) { },
//     function (error) { }
// );


// loadingManager.onLoad = function () {

//     animate();

//     // model animation on scroll desktop only
//     if (viewport.width > 1024) {
//         let lastScrollTop = 0;
//         $(window).scroll(function (event) {
//             let st = $(this).scrollTop();
//             if (st > lastScrollTop) {
//                 if (model) {
//                     model.rotation.x += 0.00075;
//                     model.rotation.y += 0.00075;
//                     model.rotation.z += 0.00075;
//                 }
//             } else {
//                 if (model) {
//                     model.rotation.x -= 0.00075;
//                     model.rotation.y -= 0.00075;
//                     model.rotation.z -= 0.00075;
//                 }
//             }
//             lastScrollTop = st;
//         });
//     }

// };

// function animate() {

//     if (model) {
//         model.rotation.y += 0.00025;
//         model.rotation.x += 0.00025;
//         model.rotation.z += 0.00025;
//     }

//     requestAnimationFrame(animate);
//     scene.updateMatrixWorld()
//     camera.updateProjectionMatrix();
//     oControls.update();
//     renderer.render(scene, camera);
//     //composer.render();
// }

// autoresize(camera, renderer);


var fadeIns = gsap.utils.toArray(".fade-in");

fadeIns.forEach((fadeIn) => {
    gsap.fromTo(fadeIn, {
        opacity: 0,
        scale: .98,
        y: 40
    }, {
        opacity: 1,
        scale: 1,
        y: 0,
        scrollTrigger: {
            trigger: fadeIn,
            start: "top+=48 bottom",
            end: "bottom+=128 bottom",
            scrub: true,
            //toggleActions: "restart none none none",
        },
    });
});

var fadeInsSlow = gsap.utils.toArray(".fade-in-slow");

fadeInsSlow.forEach((fadeIn) => {
    gsap.fromTo(fadeIn, {
        opacity: 0,
        scale: .98,
        y: 40
    }, {
        opacity: 1,
        scale: 1,
        y: 0,
        scrollTrigger: {
            trigger: fadeIn,
            start: "top+=48 bottom",
            end: "bottom+=256 bottom",
            scrub: true,
            //toggleActions: "restart none none none",
        },
    });
});

if (mobile) {
    $('.scrollToAnchor').on('click', function (e) {
        e.preventDefault();

        // Get the target element's offset position
        const targetId = '#' + $(this).data('target');
        const targetOffset = $(targetId).offset().top - 180;

        // Calculate offset based on screen width
        const offset = window.innerWidth < 1200 ? parseInt($(this).data('offset')) / 2.5 : parseInt($(this).data('offset'));

        // Scroll to the target element position with the specified offset
        window.scrollTo({
            top: targetOffset - offset,
            behavior: 'auto' // No animation
        });
    });
}



























var accordionHeader = $('.accordion__header'),
    accordionContent = $('.accordion__content')

$(accordionHeader).on('click', function () {
    if ($(this).hasClass('is-active')) {
        $(this).next(accordionContent).slideUp('normal');
        $(this).removeClass('is-active');
    }
    else {
        $(accordionHeader).not(this).next(accordionContent).slideUp('normal');
        $(accordionHeader).not(this).removeClass('is-active');
        $(this).next(accordionContent).slideDown('normal');
        $(this).addClass('is-active');
    }
});