import {
    HemisphereLight,
    DirectionalLight,
    PointLight,
    AmbientLight,
    PointLightHelper
} from 'three';

const renderMultiplier = 1;
let directLightIntensity = 4;
let ambiLightIntensity = 0.6;
let hemiLightIntensity = 2;

const ambiLight = new AmbientLight(0xd8f3ff, ambiLightIntensity)

const hemiLight = new HemisphereLight(0xffffff, 0xffffff);
hemiLight.color.setHSL(0, 1, 1);
hemiLight.intensity = hemiLightIntensity
hemiLight.groundColor.setHSL(0, 1, 1);
hemiLight.position.set(0, 0, 0);

const directLight = new DirectionalLight(0xFFFFFF); // 0xFFDC9E / FFEED1 production
directLight.intensity = directLightIntensity
directLight.color.setHex(0xFFFFFF); // 0xFFDC9E / FFEED1 production
directLight.position.set(0, 5, 5);
directLight.lookAt(0, 0, 0);
directLight.castShadow = true;
directLight.shadow.mapSize.width = 2048 * renderMultiplier;
directLight.shadow.mapSize.height = 2048 * renderMultiplier;
const d = 5
directLight.shadow.camera.left = - d;
directLight.shadow.camera.right = d;
directLight.shadow.camera.top = d;
directLight.shadow.camera.bottom = - d;
directLight.shadow.camera.far = 500;
directLight.shadow.camera.near = 0.05
directLight.shadow.normalBias = -0.0001; // -0.0001
directLight.shadow.autoUpdate = true;
directLight.shadow.radius = 2;
directLight.shadow.blurSamples = 32;
directLight.shadow.camera.updateProjectionMatrix();

const backLight = new DirectionalLight(0xFFFFFF); // 0xFFDC9E / FFEED1 production
backLight.intensity = 0
backLight.color.setHex(0xFFFFFF); // 0xFFDC9E / FFEED1 production
backLight.position.set(0, 5, -3);
backLight.lookAt(0, 0, 0);
backLight.castShadow = false;

export {
    hemiLight,
    directLight,
    ambiLight,
    backLight
}