import { MeshPhysicalMaterial, RepeatWrapping, DoubleSide, TextureLoader, MeshStandardMaterial, MeshNormalMaterial, RGBAFormat, MeshLambertMaterial } from 'three';

// panel textures
let textureLoader = new TextureLoader();

// object materials
let objMetalShiny = new MeshPhysicalMaterial({
    color: 0xEDEDED,
    metalness: 1,
    reflectivity: 0.75,
    roughness: .4,
    ior: 1.7
})

let objMetal = new MeshPhysicalMaterial({
    color: 0xEDEDED,
    metalness: 1,
    reflectivity: 0.8,
    roughness: .2,
    ior: 1.7
})

let objMetalWhite = new MeshPhysicalMaterial({
    color: 0xffffff,
    metalness: 0.4,
    reflectivity: 1,
    roughness: 0,
    ior: 1.7
})

let objMetalDark = new MeshPhysicalMaterial({
    color: 0x111111,
    metalness: 1,
    reflectivity: 0.8,
    roughness: .2,
    ior: 1.7
})

let objMetalBlue = new MeshPhysicalMaterial({
    color: 0x2840B8,
    metalness: 1,
    reflectivity: 0.75,
    roughness: .4,
    ior: 1.7
})

let objBlue = new MeshPhysicalMaterial({
    color: 0x2840B8,
    metalness: 0.2,
    reflectivity: 0.2,
    roughness: 0.8,
})

let objDark = new MeshPhysicalMaterial({
    color: 0x2462e3,
    transmission: 0,
    reflectivity: .8,
    roughness: .5,
    metalness: 1,
    ior: 1.3,
})

let objWhite = new MeshPhysicalMaterial({
    color: 0xffffff,
    transmission: 0,
    reflectivity: .8,
    roughness: .5,
    metalness: 1,
    ior: 1.3,
})


let objRubber = new MeshPhysicalMaterial({
    color: 0x000000,
    metalness: 0,
    reflectivity: 0.2,
    roughness: .8,
})


let panelSurface = new MeshPhysicalMaterial({
    color: 0x4F5358,
    transmission: 0,
    reflectivity: .8,
    roughness: .5,
    metalness: 1,
    ior: 1.3,
})

let darkWoodSurface = new MeshPhysicalMaterial({
    color: 0xffffff,
    transmission: 0,
    reflectivity: .3,
    roughness: .5,
    metalness: .75,
    ior: 1.3,
})

let lightWoodSurface = new MeshPhysicalMaterial({
    color: 0xffffff,
    transmission: 0,
    reflectivity: .3,
    roughness: .5,
    metalness: .75,
    ior: 1.3,
})

let defaultMat = new MeshPhysicalMaterial({
    color: 0xffffff
})

let particlesMat = new MeshPhysicalMaterial({
    color: 0xFFFFFF
})


let whiteShinyMat = new MeshPhysicalMaterial({
    color: 0xffffff,
    reflectivity: 1,
    clearcoat: 1,
    roughness: 0,
    metalness: 0,
    emissive: 0xbdbdbd
})

let blueShinyMat = new MeshPhysicalMaterial({
    color: 0x2840B8,
    reflectivity: 1,
    clearcoat: 1,
    roughness: 0,
    metalness: 0,
    emissive: 0x2840B8
})

let redShinyMat = new MeshPhysicalMaterial({
    color: 0xFF002B,
    reflectivity: 1,
    clearcoat: 1,
    roughness: 0,
    metalness: 0
})

let dragMat = new MeshPhysicalMaterial({
    color: 0x2740b8
})

let metalMat = new MeshPhysicalMaterial({
    color: 0xEDEDED,
    metalness: 1,
    reflectivity: 0.75,
    roughness: .4,
    ior: 1.7
})

let kufrBodyMat = new MeshPhysicalMaterial({
    color: 0x999999,
    metalness: 1,
    reflectivity: 0.6,
    roughness: .6,
    ior: 1.7
})

let miniMetalMat = new MeshPhysicalMaterial({
    color: 0xEDEDED,
    metalness: 1,
    reflectivity: 0.85,
    roughness: .22,
    ior: 1.7
})

let fortisMat = new MeshPhysicalMaterial({
    color: 0xffffff,
    metalness: 1,
    reflectivity: 0.9,
    roughness: .15,
    ior: 1.7
})

let miniFortisMat = new MeshPhysicalMaterial({
    color: 0xffffff,
    metalness: 1,
    reflectivity: 0.9,
    roughness: .15,
    ior: 1.7
})

let rubberMat = new MeshPhysicalMaterial({
    color: 0x000000
})

let miniRubberMat = new MeshPhysicalMaterial({
    color: 0x000000
})

let objectMat = new MeshStandardMaterial({
    color: 0xE6E6E6,
    transparent: true,
    opacity: 1
})

let hoverMat = new MeshStandardMaterial({
    color: 0xE6E6E6,
    transparent: true,
    opacity: 0
})

let measureMat = new MeshPhysicalMaterial({
    color: 0xFFFFFF,
    metalness: 1,
    reflectivity: 1,
    roughness: 0,
    ior: 1.7,
    thickness: 0.5,
    clearcoat: 1,
    clearcoatRoughness: 0,
})

let plasticMat = new MeshPhysicalMaterial({
    color: 0xBEBEBE,
    reflectivity: .6,
    roughness: 0.25,
    clearcoat: .15,
    clearcoatRoughness: 0.15,
})

let plasticOrangeMat = new MeshPhysicalMaterial({
    color: 0xDB5800,
    reflectivity: .45,
    roughness: 0.3,
    clearcoat: .15,
    clearcoatRoughness: 0.25,
})

let plasticBlueMat = new MeshPhysicalMaterial({
    color: 0x014c98,
    reflectivity: .5,
    roughness: 0.3,
    clearcoat: .15,
    clearcoatRoughness: 0.25,
})

let plasticWhiteMat = new MeshPhysicalMaterial({
    color: 0xffffff,
    reflectivity: .5,
    roughness: 0.3,
    clearcoat: .15,
    clearcoatRoughness: 0.25,
})

let interiorMat = new MeshLambertMaterial({
    color: 0xE4E4E7,
})

let tagMaterial = new MeshPhysicalMaterial({
    color: 0xE4E4E4,
    reflectivity: .6,
    roughness: 0.15,
    clearcoat: .15,
    clearcoatRoughness: 0.15,
    transmission: .95
})

let blackMetal = new MeshPhysicalMaterial({
    color: 0x2B2B2C,
    metalness: 1,
    reflectivity: 0.85,
    roughness: .22,
    ior: 1.7
})

let podstavecMat = new MeshPhysicalMaterial({
    color: 0x09090B,
    metalness: 0.4,
    reflectivity: 0.5,
    roughness: .6,
    ior: 1.7
})

let frameMat = new MeshPhysicalMaterial({
    color: 0x09090B,
    metalness: 0.3,
    reflectivity: 0.7,
    roughness: .3,
    ior: 1.4
})

// const textureLoader = new TextureLoader()
// const matcapTexture = textureLoader.load('images/matcaps/15.png')
// let interiorMat = new MeshMatcapMaterial()
// interiorMat.matcap = matcapTexture

let glassMat = new MeshPhysicalMaterial({
    color: 0xffffff,
    metalness: 0,
    roughness: 0,
    transmission: 1, // Set transmission for glass effect
    thickness: 1, // For controlling the amount of light passing through
    ior: 2.42, // Index of refraction
    side: DoubleSide,
    dispersion: 0.75,
})

export {
    glassMat,
    podstavecMat,
    panelSurface,
    defaultMat,
    metalMat,
    frameMat,
    rubberMat,
    objectMat,
    hoverMat,
    miniMetalMat,
    particlesMat,
    measureMat,
    fortisMat,
    plasticWhiteMat,
    miniRubberMat,
    miniFortisMat,
    dragMat,
    plasticBlueMat,
    whiteShinyMat,
    redShinyMat,
    blueShinyMat,
    interiorMat,
    objMetalShiny,
    objMetal,
    objMetalDark,
    objRubber,
    objMetalWhite,
    objMetalBlue,
    objBlue,
    objDark,
    objWhite,
    plasticMat,
    tagMaterial,
    blackMetal,
    plasticOrangeMat,
    kufrBodyMat,
    darkWoodSurface,
    lightWoodSurface
}